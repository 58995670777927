<template>
    <v-data-table
        :headers="headers"
        :items="orders"
        :footer-props="{ 'itemsPerPageOptions': [50, 100, 200, -1] }"
    >
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>Заказы</v-toolbar-title>
            </v-toolbar>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at | moment('DD.MM.YYYY HH:mm') }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
            <span class="true-false-label primary" :style="`background: ${ statuses[item.status].color }`">
                {{ statuses[item.status].title }}
            </span>
        </template>
        <template v-slot:[`item.user`]="{ item }">
            {{ item.user.name }}
        </template>
        <template v-slot:[`item.phone`]="{ item }">
            {{ item.user.phone }}
        </template>
        <template v-slot:[`item.email`]="{ item }">
            {{ item.user.email }}
        </template>
        <template v-slot:[`item.price`]="{ item }">
            {{ priceFormated(item.full_price) }}
        </template>
        <template v-slot:[`item.open`]="{ item }">
            <v-icon color="primary" @click="open(item.id)">mdi-eye</v-icon>
        </template>
    </v-data-table>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';

    import { priceFormated } from '../../helpers';

    export default {
        name: 'OrdersList',
        components: {
        },
        data: () => ({
            priceFormated,
            headers: [
                { text: 'Дата', value: 'created_at' },
                { text: 'Статус', value: 'status' },
                { text: 'Пользователь', value: 'user' },
                { text: 'Телефон', value: 'phone' },
                { text: 'Email', value: 'email' },
                { text: 'Сумма', value: 'price' },
                { text: '', align: 'right', value: 'open' },
            ],
        }),
        computed: {
            ...mapState('orders', {
                statuses: state => state.statuses,
                orders: state => state.entities
            }),
        },
        async mounted() {
            await store.dispatch('orders/statuses');
            await store.dispatch('orders/fetch', {});
        },
        methods: {
            getBadge (status) {
                return status ? 'success' : 'error';
            },
            async open(id) {
                await this.$router.push({ name: 'orders-detail', params: { id } });
            }
        }
    }
</script>